<div fxLayout="column" fxLayoutAlign="center center" >
  
  <!--  -->
  <img src="/assets/logo_smaller.png">

  <!--  -->
  <mat-card>
    <mat-card-header> </mat-card-header>
    <mat-card-content>
        <b>What the f*ck?</b> <br><br> 
        
        Das ist die offizielle Website des noch nicht ganz so offiziellen <b> Winterthurer Tischfussball Clubs </b>. 

        Unser Ziel ist es, einen Treffpunkt für Tischfussballbegeisterte zu schaffen und unsere Community mit der Zeit auszubauen. 
        
        <br><br>

        Komm vorbei und mach mit! Ob jung oder alt, Profi oder Anfänger - bei uns bist du willkommen.

        Ein- bis zweimal in der Woche treffen wir uns in unserem "Trainingsraum" für Sparring, DYP oder Einzelspiele.  
        


        <br><br><br>
        <b>Who the f*ck? </b> <br><br> 
        
        Hast du Lust auf ein Spiel? Dann melde dich bei uns (Martin und Fabian) unter folgender Adresse: <br><br> 
        
        <a href="mailto:info@wtfclub.ch">info@wtfclub.ch</a>
        
        <br><br><br>
        <b>Where the f*ck? </b> <br><br> zwei Minuten von <a href="https://www.google.com/maps/place/Pflanzschulstrasse/@47.498153,8.7391227,19.75z/data=!4m13!1m7!3m6!1s0x479a99906e5c707b:0xe0c8b2374c04565d!2sPflanzschulstrasse,+8400+Winterthur!3b1!8m2!3d47.4977766!4d8.7399969!3m4!1s0x479a999a9c26aef7:0xf5e2a483997c61d2!8m2!3d47.4981725!4d8.7390626"> Winterthur Pflanzschulstrasse</a> entfernt
    </mat-card-content>
  </mat-card>

  <!--  
  <mat-card>
    <mat-card-header>
      <mat-card-title> KONTAKT </mat-card-title> 
    </mat-card-header>
    <mat-card-content>
        supercoole@siechen.ch
    </mat-card-content>
  </mat-card>
  -->
</div>

